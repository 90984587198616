var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "app__content w-content"
  }, [_c('div', {
    staticClass: "w-content__inner"
  }, [_c('nuxt')], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }